import { Link } from 'gatsby'
import React from 'react'
import Layout from '../components/Layout'
import NotFound from '../img/404.svg'
import Unauthorized from '../img/unauthorized.svg'

const NotFoundPage = ({
  title = 'Uh oh. Looks like nothing exists on this path.',
  errorType = 404,
  link = {
    type: 'internal',
    path: '/',
    innerText: 'Go home',
  },
}) => (
  <Layout>
    <section className="section mt-81">
      <div className="container">
        <div className="content">
          <section className="section--small">
            <div className="columns">
              <div className="column is-vcentered">
                <h1 className="mb-4">{title}</h1>
                <span className="title mt-16 normal">
                  {errorType === 404
                    ? 'Nothing to see here...'
                    : 'Not authorized. Please login.'}
                </span>
                {link.type === 'internal' ? (
                  <Link
                    to={link.path}
                    className="button w-100 has-text-white has-background-primary"
                  >
                    <strong>{link.innerText}</strong>
                  </Link>
                ) : (
                  <a
                    href={link.path}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="button bold w-fc has-text-white has-background-primary"
                  >
                    {link.innerText}
                  </a>
                )}
              </div>
              <div className="column">
                <figure className="image">
                  <img
                    src={errorType === 404 ? NotFound : Unauthorized}
                    alt={
                      errorType === 404
                        ? '404 not found alien attack'
                        : 'webpage locked'
                    }
                  />
                </figure>
              </div>
            </div>
          </section>
        </div>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
